<template>
  <div>
    <Table border :columns="orderLogColumns" :data="orderLogData" :loading="loading" max-height="450"></Table>
    <Button v-if="orderLogParams.nextToken !== null" @click="getOrderLog()" icon="ios-arrow-down" long>{{ $t('key1003843') }}</Button>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {getUserNameByUserId} from "@/utils/common";

export default {
  name: 'orderLog',
  mixins: [Mixin],
  data () {
    let v = this;
    return {
      loading: false,
      orderLogColumns: [
        {
          key: 'updatedBy',
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000221'), // 操作人
          width: 130,
          align: 'center',
          render: (h, params) => {
            return h('div', getUserNameByUserId(params.row.updatedBy,v));
          }
        },
        {
          key: 'logContentDesc',
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000219'), // 操作内容描述
          minWidth: 230
        },
        {
          key: 'updatedTime',
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000220'), // 操作时间
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', {}, v.$uDate.getDataToLocalTime(params.row.updatedTime, 'fulltime'));
          }
        }
      ],
      orderLogData: [],
      orderLogParams: {
        'pickingId': null,
        'nextToken': '1',
        'pageSize': 10,
        showNotes: 1
      }
    };
  },
  props: {
    pickingId: { // 包裹id
      default: '',
      required: true,
      type: String
    },
    showNotes: { // 是否展示备注 默认展示
      default: 1,
      type: Number
    },
    from: { // 标识是出库单还是入库单
      default: 'outStock',
      type: String
    }
  },
  created () {
    this.getUserName();
  },
  watch: {
    pickingId (n, o) {
      if (n !== '') {
        this.On_searchOrderLogInit();
      }
    }
  },
  methods: {
    On_searchOrderLogInit () {
      /**
       * 搜索方法 首次加载 （日志增量加载）
       * */
      let v = this;
      v.orderLogParams.pickingId = v.pickingId;
      v.orderLogParams.showNotes = v.showNotes;
      v.orderLogParams.nextToken = null;
      v.orderLogData = [];
      v.getOrderLog();
    },
    getOrderLog () {
      /**
       * 获取订单日志
       * */
      let v = this;
      v.loading = true;
      // if (v.from === 'inStock') {
      //   v.loading = false;
      //   v.orderLogData = v.$store.state.operationLog;
      // } else {
      //   v.axios.post(api.get_wmsPickingLog, v.orderLogParams).then(response => {
      //     v.loading = false;
      //     if (response.data.code === 0) {
      //       v.orderLogParams.nextToken = response.data.datas.nextToken;
      //       v.orderLogData = [...v.orderLogData, ...response.data.datas.result];
      //     }
      //   });
      // }
      v.axios.post(api.get_wmsPickingLog, v.orderLogParams).then(response => {
        v.loading = false;
        if (response.data.code === 0) {
          v.orderLogParams.nextToken = response.data.datas.nextToken;
          v.orderLogData = [...v.orderLogData, ...response.data.datas.result];
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
